import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { apply } from '../../features/ContainerQuery';
import useResizeObserver from '../../features/useResizeObserver';
/**
 * Bronson ProductTable component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-product-table.html
 * @constructor
 */
export function ProductTable({ children, className, headingLevel = 3, info, summary, title, testId, ...otherProps }) {
    const ref = useRef(null);
    const applyQC = useCallback(apply, [apply]);
    /**
     * Apply `onResizeHandler` handler.
     */
    useResizeObserver({
        ref,
        onResizeHandler: applyQC,
    });
    const classNameList = classNames('c-product-table', className).trim();
    const CustomProductTableHeadingLevel = `h${headingLevel}`;
    const productTableHeading = (React.createElement(CustomProductTableHeadingLevel, { className: "c-product-table__title" }, title));
    const productTableSummary = (React.createElement("div", { className: "c-product-table__section  c-product-table__section--summary" }, summary));
    const productTableInfo = React.createElement("div", { className: "c-product-table__information" }, info);
    return (React.createElement("div", { ref: ref, className: classNameList, "data-testid": testId, ...otherProps },
        title && productTableHeading,
        children,
        summary && productTableSummary,
        info && productTableInfo));
}
/**
 * Bronson ProductTableSection (nested).
 * @internal
 * @constructor
 */
export function ProductTableSection({ children, className, innerHeadingLevel = 4, innerTitle, }) {
    const classNameList = classNames('c-product-table__section', className).trim();
    const CustomProductTableSectionHeadingLevel = `h${innerHeadingLevel}`;
    const productTableSectionHeading = (React.createElement(CustomProductTableSectionHeadingLevel, { className: "c-product-table__inner-title" }, innerTitle));
    return (React.createElement("div", { className: classNameList },
        innerTitle && productTableSectionHeading,
        children));
}
ProductTableSection.displayName = 'ProductTable.Section';
ProductTable.Section = ProductTableSection;
/**
 * Bronson ProductTableComment (nested).
 * @internal
 * @constructor
 */
export function ProductTableComment({ children, className, type }) {
    const classNameList = classNames({
        // Convenience props from Bronson.
        'c-product-table__item-term-comment': type === 'term',
        'c-product-table__item-detail-comment': type === 'detail',
    }, className).trim();
    return React.createElement("span", { className: classNameList }, children);
}
ProductTableComment.displayName = 'ProductTable.Comment';
ProductTable.Comment = ProductTableComment;
