import React, { useCallback, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
/**
 * Hide tooltips when they and their children lose focus.
 * @see https://atomiks.github.io/tippyjs/v6/plugins/#hideonpopperblur
 * @type {{defaultValue: boolean, name: string, fn(*=): {onCreate(): void}}}
 */
export const hideOnPopperBlur = {
    name: 'hideOnPopperBlur',
    defaultValue: true,
    fn(instance) {
        return {
            onCreate() {
                instance.popper.addEventListener('focusout', (event) => {
                    if (instance.props.hideOnPopperBlur &&
                        event.relatedTarget &&
                        !instance.popper.contains(event.relatedTarget)) {
                        instance.hide();
                    }
                });
            },
        };
    },
};
/**
 * Additional {@link Partial<PopperOptions>} options.
 */
const popperOptions = {
    // [2]
    modifiers: [
        {
            name: 'flip',
            options: {
                /** @TODO: Check position values with {@link TooltipProps.placement}. */
                fallbackPlacements: ['bottom', 'right'],
            },
        },
        {
            name: 'preventOverflow',
            options: {
                altAxis: true,
                tether: false,
            },
        },
    ],
};
/**
 * Bronson Tooltip component.
 * @desc Tooltips are user-triggered messages that provide additional information about a page element or feature.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-tooltip.html
 * @constructor
 */
export function Tooltip({ alwaysVisible, children, className, content, placement = 'bottom', ...otherProps }) {
    const buttonClassNameList = classNames({
        'c-tooltip': true,
    }, className).trim();
    function renderChildren() {
        if (typeof children === 'string') {
            return (React.createElement("button", { type: "button", className: buttonClassNameList }, children));
        }
        return children;
    }
    const triggerObj = useMemo(() => {
        if (alwaysVisible) {
            return { trigger: 'manual' };
        }
        return {};
    }, [alwaysVisible]);
    const onTrigger = useCallback((instance) => {
        instance.reference.parentElement.style.setProperty('transition-duration', '0s');
        instance.reference.parentElement.style.setProperty('z-index', '2001');
    }, []);
    const onHidden = useCallback((instance) => {
        instance.reference.parentElement.style.removeProperty('z-index');
        instance.reference.parentElement.style.removeProperty('transition-duration');
    }, []);
    return (React.createElement(Tippy, { content: content, arrow: true, interactive: true, allowHTML: true, placement: placement, theme: "default", animation: "default", showOnCreate: alwaysVisible, hideOnClick: !alwaysVisible, onTrigger: onTrigger, onHidden: onHidden, popperOptions: popperOptions, plugins: [hideOnPopperBlur], ...triggerObj, ...otherProps }, renderChildren()));
}
