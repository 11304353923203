import React from 'react';
import classNames from 'classnames';
import { Progress } from '../Progress/Progress';
import { Icon } from '../Icon/Icon';
import { ReactLink } from '../ReactLink/ReactLink';
/**
 * Bronson UploadItem component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-upload-item.html
 * @constructor
 */
export function UploadItem({ className, error, fileName, fileSize, filesizeProgress, fileType, hideMainButton, hideSecondaryButton, icon, imgAlt, imgLinkHref, imgLinkTitle, imgProps, imgSrc, loading, mainButtonLink, mainButtonLinkExternal, mainButtonProps, message, onMainButtonClick, onSecondaryButtonClick, preview, progressValue, secondaryButtonLink, secondaryButtonLinkExternal, secondaryButtonProps, success, testId, ...otherProps }) {
    const divClassNameList = classNames('c-upload-item', {
        // Convenience props from Bronson variants.
        'c-upload-item--preview': preview,
        'is-error': error,
        'is-loading': loading,
        'is-success': success,
    }, className).trim();
    function renderIfIcon() {
        if (icon) {
            return React.createElement(Icon, { className: "c-upload-item__icon", name: icon, "data-filetype": fileType });
        }
        return null;
    }
    function renderIfImage() {
        if (imgSrc) {
            return (React.createElement("div", { className: "c-upload-item__image-wrapper" },
                React.createElement("a", { className: "c-upload-item__image-link", href: imgLinkHref, title: imgLinkTitle, target: "_blank", rel: "noopener noreferrer" },
                    React.createElement("img", { className: "c-upload-item__image", src: imgSrc, alt: imgAlt, ...imgProps }))));
        }
        return null;
    }
    function renderIfMain() {
        if (hideMainButton) {
            return null;
        }
        const props = {
            className: 'c-upload-item__interaction-icon c-upload-item__interaction-icon--main',
            onClick: onMainButtonClick,
            ...mainButtonProps,
        };
        return mainButtonLink ? (React.createElement(ReactLink, { normalLink: true, href: mainButtonLink, target: mainButtonLinkExternal ? '_blank' : undefined, rel: mainButtonLinkExternal ? 'noopener noreferrer' : undefined, ...props })) : (
        // @ts-ignore @TODO: Should be replaced by the Button component, @see BRON-11670.
        React.createElement("button", { type: "button", ...props }));
    }
    function renderIfSecondary() {
        if (hideSecondaryButton) {
            return null;
        }
        const props = {
            className: 'c-upload-item__interaction-icon c-upload-item__interaction-icon--secondary',
            onClick: onSecondaryButtonClick,
            ...secondaryButtonProps,
        };
        return secondaryButtonLink ? (React.createElement(ReactLink, { normalLink: true, href: secondaryButtonLink, target: secondaryButtonLinkExternal ? '_blank' : undefined, rel: secondaryButtonLinkExternal ? 'noopener noreferrer' : undefined, ...props })) : (
        // @ts-ignore @TODO: Should be replaced by the Button component, @see BRON-11670.
        React.createElement("button", { type: "button", ...props }));
    }
    function renderIfUploadItemMessage() {
        if (message) {
            return React.createElement("span", { className: "c-upload-item__message" }, message);
        }
        return null;
    }
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList },
        renderIfIcon(),
        renderIfImage(),
        React.createElement("div", { className: "c-upload-item__content" },
            React.createElement("div", { className: "c-upload-item__title-row" },
                React.createElement("p", { className: "c-upload-item__title" }, fileName),
                React.createElement("div", { className: "c-upload-item__interaction-icons" },
                    renderIfMain(),
                    renderIfSecondary(),
                    React.createElement(Icon, { className: "c-upload-item__status-icon", viaCss: true }))),
            React.createElement("div", { className: "c-upload-item__progress" },
                React.createElement(Progress, { value: progressValue, success: success, error: error })),
            React.createElement("p", { className: "c-upload-item__subtitle-row" },
                React.createElement("span", { className: "c-upload-item__filesize-progress" },
                    filesizeProgress,
                    " of "),
                React.createElement("span", { className: "c-upload-item__filesize" }, fileSize),
                React.createElement("span", { className: "c-upload-item__filesize-progress" },
                    " (",
                    progressValue,
                    "% uploaded)"),
                renderIfUploadItemMessage()))));
}
