import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/*
 * Bronson IconList component.
 */
export const IconList = ({ children, className, testId, ...otherProps }) => {
    const ulClassNameList = classNames('c-icon-list', className).trim();
    return (React.createElement("ul", { ...otherProps, "data-testid": testId, className: ulClassNameList }, children));
};
/*
 * Bronson Item component (nested).
 */
function Item({ children, icon, iconClassName, iconMeaning, success, ...otherProps /* in <li> tag */ }) {
    const iClassNameList = classNames('c-icon-list__icon', {
        'u-text-success': success,
    }, iconClassName).trim();
    function renderIfItemIconMeaning() {
        if (iconMeaning) {
            return React.createElement("span", { className: "u-visually-hidden" }, iconMeaning);
        }
        return null;
    }
    return (React.createElement("li", { ...otherProps },
        React.createElement(Icon, { className: iClassNameList, name: icon }),
        renderIfItemIconMeaning(),
        children));
}
Item.displayName = 'IconList.Item';
IconList.Item = Item;
