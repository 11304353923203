/* eslint-disable jsx-a11y/aria-role */
import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import getTextContent from '../../utils/getTextContent';
import { apply } from '../../features/ContainerQuery';
import useResizeObserver from '../../features/useResizeObserver';
/**
 * Bronson PriceBox component.
 * @see https://bronson.vwfs.tools/bluelabel/components/detail/bronson-price-box.html
 * @desc Please note: In order to announce the discount value, discount price
 *       and actual price to assistive technology, apply both `[aria-label]` and
 *       `[role=text]` to those elements. This ensures accessible content without
 *       relying on the visual appearance.
 * @constructor
 */
export function PriceBox({ standalone, header, discountValue, ariaLabelDiscountValue, discountOldPrice, ariaLabelDiscountOldPrice, price, ariaLabelPrice, textAbovePrice, textBelowPrice, additionalInfo, testId, ...otherProps }) {
    const ref = useRef(null);
    const applyQC = useCallback(apply, [apply]);
    /**
     * Apply `onResizeHandler` handler.
     */
    useResizeObserver({
        ref,
        onResizeHandler: applyQC,
    });
    const CustomTag = `${standalone ? 'article' : 'div'}`;
    const CustomTagHeader = `${standalone ? 'header' : 'div'}`;
    const classNameList = classNames('c-price-box ', { 'c-price-box--integrated ': !standalone }).trim();
    const tagClassNameList = classNames('c-tag ', 'c-tag--negative ', 'c-price-box__discount__value ').trim();
    function renderHeader() {
        if (header) {
            return React.createElement(CustomTagHeader, { className: "c-price-box__header" }, header);
        }
        return null;
    }
    function renderDiscountValue() {
        if (discountValue) {
            return (React.createElement("span", { className: tagClassNameList },
                React.createElement("span", { className: "c-tag__label", "aria-label": `${ariaLabelDiscountValue} ${getTextContent(discountValue)}`, role: "text" }, discountValue)));
        }
        return null;
    }
    function renderDiscountOldPrice() {
        if (discountOldPrice) {
            return (React.createElement("s", { className: "c-price-box__discount__old-price", "aria-label": `${ariaLabelDiscountOldPrice} ${getTextContent(discountOldPrice)}`, role: "text" }, discountOldPrice));
        }
        return null;
    }
    function renderDiscount() {
        if (discountValue || discountOldPrice) {
            return (React.createElement("div", { className: "c-price-box__discount" },
                renderDiscountValue(),
                renderDiscountOldPrice()));
        }
        return null;
    }
    function renderPrice() {
        return (React.createElement("span", { className: "c-price-box__price", "aria-label": `${ariaLabelPrice} ${getTextContent(price)}`, role: "text" }, price));
    }
    function renderTextAbovePrice() {
        if (textAbovePrice) {
            return React.createElement("span", { className: "c-price-box__text" }, textAbovePrice);
        }
        return null;
    }
    function renderTextBelowPrice() {
        if (textBelowPrice) {
            return React.createElement("span", { className: "c-price-box__text" }, textBelowPrice);
        }
        return null;
    }
    function renderAdditionalInfo() {
        if (additionalInfo) {
            return (React.createElement("div", { className: "c-price-box__additional-info" },
                React.createElement("p", { className: "c-price-box__additional-info__text" }, additionalInfo)));
        }
        return null;
    }
    return (React.createElement(CustomTag, { ...otherProps, "data-testid": testId, className: classNameList, ref: ref },
        renderHeader(),
        React.createElement("div", { className: "c-price-box__body" },
            React.createElement("div", { className: "c-price-box__body__content" },
                renderTextAbovePrice(),
                renderDiscount(),
                renderPrice(),
                renderTextBelowPrice()),
            renderAdditionalInfo())));
}
