import React, { useRef, useEffect, useImperativeHandle, useState, useCallback, } from 'react';
import Plyr from 'plyr';
import { useBronson } from '../../features/useBronson';
/**
 * Possible connection type values.
 * @type {Readonly<{"slow-2g": number, "2g": number, "3g": number, "4g": number}>}
 */
export const EffectiveConnectionTypeEnum = Object.freeze({
    'slow-2g': 0,
    '2g': 1,
    '3g': 2,
    '4g': 3,
});
/**
 * Compare the current and the preferred downlink.
 * @return {boolean} - If the current connection matches at least the preferred one.
 */
function checkConnectivity(connection, autoplayMinimumConnection) {
    /**
     * If no preferred connection type was passed we assume a great connection type (4G).
     * @type {string}
     */
    const preferredConnectionType = autoplayMinimumConnection ?? '4g';
    const currentDownlink = EffectiveConnectionTypeEnum[connection?.effectiveType];
    const minDownlink = EffectiveConnectionTypeEnum[preferredConnectionType];
    return currentDownlink < minDownlink;
}
/**
 * Bronson Video component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-video.html
 */
export const Video = React.forwardRef(({ alt, autoPlay, autoplayMinimumConnection, captions, captionsLabel, captionsLanguage, captionsSrc, controls, fullscreen, iconUrl, id, loop, muted, onEnded, onEnterFullscreen, onExitFullscreen, onPause, onPlay, onPlaying, poster, selfHosted, service, src, testId, ...otherProps }, ref) => {
    const { cdn: bronsonCDN } = useBronson();
    const [apiForcedPause, setApiForcedPause] = useState(false);
    const [insufficientConnection, setInsufficientConnection] = useState(false);
    const videoRef = useRef(null);
    const plyrInstance = useRef(null);
    /**
     * Handle play-state of Video depending on if the current page is visible.
     * If the user paused the video via {@link this.apiForcedPause} or
     * the video has not been played yet, do not play it when the
     * page visibility changes to unhidden/visible.
     */
    const handleVisibilityChange = useCallback(() => {
        if (plyrInstance?.current) {
            if (!plyrInstance?.current?.playing) {
                setApiForcedPause(true);
            }
            !document.hidden && !apiForcedPause ? plyrInstance?.current?.play() : plyrInstance?.current?.pause();
        }
    }, [plyrInstance, apiForcedPause]);
    /**
     * Get the current network information. Only supported via Chromium for now.
     */
    // @ts-ignore @TODO: Check if a custom type could help here.
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    /**
     * Get the `prefers-reduced-motion` CSS media query setting.
     * @type {MediaQueryList}
     */
    const reduceMotionMediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');
    const prefersReducedMotion = reduceMotionMediaQueryList?.matches;
    /**
     * Check the page visibility and stop/resume the video on `visibilitychange` event.
     */
    document.addEventListener('visibilitychange', handleVisibilityChange);
    useEffect(() => {
        if (autoplayMinimumConnection && !prefersReducedMotion) {
            /**
             * Check if the current connection is insufficient and pause the video.
             * Otherwise, check if an autoplay was preferred and play it.
             */
            setInsufficientConnection(connection ? checkConnectivity(connection, autoplayMinimumConnection) : false);
        }
    }, [autoplayMinimumConnection, connection, prefersReducedMotion]);
    /**
     * NOTE: Refs and imperative handling are here intended as API for media playback is forwarded to parent
     * state resides within Plyr player and is not mapped to another state object copy.
     */
    useImperativeHandle(ref, () => ({
        // @ts-ignore @TODO: Fix when all browsers return a Promise.
        play: () => {
            plyrInstance?.current?.play();
        },
        pause: () => {
            plyrInstance?.current?.pause();
        },
        stop: () => {
            plyrInstance?.current?.stop();
        },
        fullscreen: () => {
            plyrInstance?.current?.fullscreen.enter();
        },
    }));
    useEffect(() => {
        if (!videoRef.current)
            return undefined;
        const plyrConfig = {
            debug: false,
            autoPlay: !insufficientConnection && autoPlay ? true : null,
            invertTime: false,
            controls: controls === false
                ? null
                : ['play', 'play-large', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen'],
            fullscreen: fullscreen ?? {
                fallback: true,
            },
            youtube: {
                noCookie: true,
            },
            urls: {
                youtube: {
                    /**
                     * Disable YouTube API (for title/aspect ratio calculation)
                     * calls through third-party Noembed service.
                     * @see https://noembed.com/
                     */
                    api: '',
                },
            },
            /**
             * Make Plyr respect `[muted]`.
             * @see https://github.com/sampotts/plyr/issues/1495#issuecomment-659735226
             * @type {{enabled: boolean}}
             */
            muted,
            storage: muted ? { enabled: false } : {},
            volume: muted ? 0 : null,
            iconUrl: iconUrl || (bronsonCDN ? `${bronsonCDN}/svg/plyr-sprite.svg` : null),
        };
        if (!plyrInstance.current) {
            plyrInstance.current = new Plyr(videoRef.current, plyrConfig);
            plyrInstance?.current?.on?.('playing', (event) => onPlaying?.(event.detail.plyr));
            plyrInstance?.current?.on?.('play', (event) => onPlay?.(event.detail.plyr));
            plyrInstance?.current?.on?.('pause', (event) => onPause?.(event.detail.plyr));
            plyrInstance?.current?.on?.('ended', (event) => onEnded?.(event.detail.plyr));
            plyrInstance?.current?.on?.('enterfullscreen', (event) => onEnterFullscreen?.(event.detail.plyr));
            plyrInstance?.current?.on?.('exitfullscreen', (event) => onExitFullscreen?.(event.detail.plyr));
        }
        const instance = plyrInstance.current;
        return () => {
            instance.destroy();
        };
    }, [
        videoRef,
        autoPlay,
        onPlaying,
        onPlay,
        onPause,
        onEnded,
        onEnterFullscreen,
        onExitFullscreen,
        iconUrl,
        bronsonCDN,
        muted,
        controls,
        fullscreen,
        insufficientConnection,
    ]);
    /**
     * Assert media sources for the `<video>` element.
     * @returns {unknown[]|null}
     */
    const videoSources = () => {
        if (Array.isArray(src)) {
            return src.map(({ path, type }) => React.createElement("source", { key: `${type}${path}`, type: `video/${type}`, src: path }));
        }
        return null;
    };
    const renderTrack = () => captions && React.createElement("track", { kind: "captions", label: captionsLabel, src: captionsSrc, srcLang: captionsLanguage, default: true });
    /**
     * NOTE: Need to wrap a `<div>` around as Plyr is manipulating complete DOM and React is otherwise not able to unmount.
     */
    return (React.createElement("div", null, selfHosted ? (React.createElement("video", { ref: videoRef, poster: poster, className: "js-video", controls: controls, autoPlay: !insufficientConnection && autoPlay ? true : undefined, loop: loop, muted: true, "data-testid": testId, ...otherProps },
        videoSources(),
        renderTrack(),
        alt)) : (React.createElement("div", { ref: videoRef, "data-plyr-provider": service, "data-plyr-embed-id": id, className: "js-video", "data-testid": testId, ...otherProps }))));
});
