import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import imagesloaded from 'imagesloaded';
import debounce from 'lodash.debounce';
import Flickity from 'flickity-fullscreen';
/**
 * BasicSlider component.
 * @desc Internal component to utilise a {@link FlickityInstance} slider.
 * @internal
 * @constructor
 */
export function BasicSlider({ elementType = 'div', options, className, flickityRef, onReady = () => { }, testId, children, sliderCounter = false, ...otherProps }) {
    const CustomElement = `${elementType}`;
    const flkty = useRef(null);
    const [ready, setReady] = useState(false);
    const [isAlreadyReady, setIsAlreadyReady] = useState(false);
    const [flickityNode, setFlickityNode] = useState(null);
    /**
     * Initialize selected index and draggable and reload cells.
     */
    useEffect(() => {
        const { draggable, initialIndex } = options ?? {};
        if (ready && !isAlreadyReady) {
            setIsAlreadyReady(true);
            const isActive = flkty.current?.isActive;
            if (flkty.current) {
                flkty.current?.deactivate();
                flkty.current.selectedIndex = initialIndex || 0;
                if (draggable === undefined) {
                    // @ts-ignore @FIXME: Check if we can omit this by not using ReactNode.
                    flkty.current.options.draggable = children ? children.length > 1 : false;
                }
                else {
                    if (flkty.current.options) {
                        flkty.current.options.draggable = draggable;
                    }
                }
            }
            if (isActive) {
                flkty.current?.activate();
            }
            if (options?.imagesLoaded && flickityNode) {
                imagesloaded(flickityNode, () => {
                    flkty.current?.reloadCells();
                });
            }
        }
        else if (ready && isAlreadyReady) {
            onReady(flkty?.current);
        }
        else {
            flkty.current?.reloadCells();
        }
        return () => { };
    }, [options, children, flickityNode, ready, isAlreadyReady, onReady]);
    /**
     * Add children to flickity slider element via React Portal and set ready state.
     */
    const renderPortal = useCallback(() => {
        if (flickityNode) {
            const sliderNode = flickityNode.querySelector('.flickity-slider');
            // @TODO: Cast to node as we know that it cannot be null.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const element = createPortal(children, sliderNode);
            const setFlickityReady = () => {
                if (!ready) {
                    const changeToReady = () => setReady(true);
                    if (options?.imagesLoaded) {
                        imagesloaded(flickityNode, changeToReady);
                    }
                    else {
                        changeToReady();
                    }
                }
                return null;
            };
            setTimeout(setFlickityReady, 0);
            return element;
        }
        return null;
    }, [flickityNode, children, ready, options?.imagesLoaded]);
    /**
     * Create the FlickityInstance object and save underlying node element to a React state.
     * Using callback refs, @see https://reactjs.org/docs/refs-and-the-dom.html#callback-refs .
     */
    const flickityWrapperRef = useCallback((node) => {
        if (!flkty.current) {
            flkty.current = new Flickity(node, options);
            if (flickityRef) {
                flickityRef(flkty.current);
            }
            setFlickityNode(node);
        }
        return null;
    }, [options, flickityRef, setFlickityNode]);
    return (React.createElement(CustomElement, { className: className, ref: flickityWrapperRef, "data-testid": testId, "data-slider-counter": sliderCounter, ...otherProps }, renderPortal()));
}
export default BasicSlider;
export const FLICKITY_BUTTONS_CLASS = 'flickity-prev-next-button';
export const FLICKITY_DOTS_CLASS = 'flickity-page-dots';
export const FLICKITY_RESIZE_CLASS = 'flickity-resize';
/**
 * Simple hook for handling {@link BasicSlider} side effects.
 * Handle the following side effects in different components in one place:
 * - Handle toggling slider controls.
 * - Dynamic children changes.
 * - Initial index selection.
 * - Managing slide height for different side effects inside slider content (e.g. font-loading glitches).
 */
export const useBasicSlider = (flickityRef, elements, { defaultSelected, hasNestedMutations, hasDynamicElements = true, initialIndex, initialResizeThreshold = 0, selected, }) => {
    /**
     * Handle {@link BasicSlider} controls.
     */
    const toggleSliderControls = useCallback(() => {
        if (flickityRef) {
            const size = flickityRef?.size?.width;
            const { slideableWidth } = flickityRef;
            const slideable = (slideableWidth ?? 0) > (size ?? 0);
            const pageDotsHTMLCollection = flickityRef.element?.getElementsByClassName(FLICKITY_DOTS_CLASS);
            const flickityButtonsHTMLCollection = flickityRef.element?.getElementsByClassName(FLICKITY_BUTTONS_CLASS);
            /**
             * This fixes a bug where FlickityInstance is unable to reassign the draggable
             * behavior when resizing from a big to small viewport when `groupCells`
             * or `contain` options are set. The fix only kicks in when the `draggable`
             * option in FlickityInstance was set.
             * @see https://flickity.metafizzy.co/options.html#draggable
             * @see https://github.com/metafizzy/flickity/issues/960
             */
            if (flickityRef.options?.draggable) {
                // eslint-disable-next-line no-param-reassign
                flickityRef.isDraggable = slideable;
                flickityRef.element?.classList.toggle('is-draggable', slideable);
                flickityRef.element?.classList.toggle('is-slideable', slideable);
            }
            Array.from(pageDotsHTMLCollection ?? []).forEach((pageDots) => {
                pageDots.classList.toggle('is-slideable', slideable);
            });
            Array.from(flickityButtonsHTMLCollection ?? []).forEach((btn) => {
                btn.classList.toggle('is-slideable', slideable);
            });
        }
    }, [flickityRef]);
    /**
     * Handle {@link BasicSlider} side-effects.
     */
    useEffect(() => {
        const instance = flickityRef;
        if (instance) {
            const resizeFn = instance.resize.bind(instance);
            instance.resize = () => {
                instance.element?.classList.remove(FLICKITY_RESIZE_CLASS);
                resizeFn();
                requestAnimationFrame(() => {
                    instance.element?.classList.add(FLICKITY_RESIZE_CLASS);
                    /**
                     * Always keep the selected items visible.
                     */
                    if (selected !== null && selected !== undefined) {
                        instance.selectCell(selected, false, true);
                    }
                });
            };
            /**
             * Check if we have an initial value to instantly show this slide.
             */
            if (initialIndex !== null && initialIndex !== undefined) {
                /**
                 * We select the cell here as we deal with `groupCell`.
                 * @see https://flickity.metafizzy.co/api.html#selectcell
                 */
                instance.selectCell(initialIndex, false, true);
            }
            const onReady = () => {
                debounce(instance.resize, initialResizeThreshold)();
                /**
                 * Fixes incorrect height calculation.
                 * Trigger resize after all fonts are loaded.
                 */
                document?.fonts?.ready?.then(() => {
                    debounce(instance.resize, initialResizeThreshold)();
                });
                /**
                 * Fixes nasty layout bug for some journeys.
                 * The {@link initialResizeThreshold} must be increased for this to work.
                 * @see https://jira.platform.vwfs.io/browse/BRON-7534
                 */
                const timeout = setTimeout(() => {
                    resizeFn();
                }, initialResizeThreshold + 50);
                return () => {
                    timeout && clearTimeout(timeout);
                };
            };
            instance.on?.('select', toggleSliderControls);
            instance.on?.('ready', onReady);
            return () => {
                instance.off?.('select', toggleSliderControls);
                instance.off?.('ready', onReady);
            };
        }
        return () => { };
    }, [flickityRef, initialIndex, initialResizeThreshold, selected, toggleSliderControls]);
    /**
     * Handle {@link BasicSlider} when children change dynamically.
     */
    useEffect(() => {
        const instance = flickityRef;
        if (instance && elements && hasDynamicElements) {
            /**
             * Resize FlickityInstance and recalculate the position of all children.
             * @see https://flickity.metafizzy.co/api.html#reloadcells
             * @see https://flickity.metafizzy.co/api.html#resize
             */
            instance.reloadCells();
            instance.resize();
        }
    }, [flickityRef, elements, defaultSelected, selected, hasDynamicElements]);
    /**
     * Effect to track any child mutations inside the Compare and Select
     * due to custom ContainerQueries.
     */
    useEffect(() => {
        if (hasNestedMutations) {
            let observer;
            if (flickityRef) {
                /**
                 * Check for nested mutations via ContainerQuery observer.
                 * @type {MutationObserver}
                 */
                observer = new MutationObserver((mutationRecords) => {
                    const nestedCQMutation = mutationRecords
                        .filter((mr) => mr.type === 'attributes')
                        .find((mr) => mr.attributeName === 'data-container-initialized');
                    /**
                     * Recalculate the slider when a nested ContainerQuery mutation was observed.
                     */
                    if (nestedCQMutation) {
                        flickityRef.resize();
                    }
                });
                /**
                 * Observe the subtree for attribute mutations.
                 */
                if (flickityRef.element) {
                    observer.observe(flickityRef.element, {
                        attributes: true,
                        subtree: true,
                    });
                }
            }
            return () => {
                observer?.disconnect();
            };
        }
        return () => { };
    }, [flickityRef, elements, defaultSelected, hasNestedMutations]);
};
