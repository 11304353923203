import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormFieldContext } from '../FormField/FormFieldContext';
/**
 * Bronson Textarea component.
 * Essentially, a `<textarea>` element uses the same markup as input.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-textarea.html
 * @constructor
 */
export function Textarea({ charCounter, children, enableMaxLength = true, error, errorMessage, id, maxCounter, modifierStates, onChange, success, testId, ...otherProps }) {
    /**
     * Track the current character count.
     */
    const [currentCount, setCurrentCount] = useState(0);
    /**
     * Track the internal state via {@link FormFieldContext}.
     * @type {object}
     */
    const context = useContext(FormFieldContext);
    /**
     * 1. Update the context state and mark the component as active.
     * 2. Set the {@link currentCount} based on the character length of the `<textarea>`.
     */
    useEffect(() => {
        if (context?.setElementActive) {
            context.setElementActive(!!children);
        }
        setCurrentCount(children?.length ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);
    const wrapperClassNameList = classNames('c-input', 'c-input--textarea', {
        'is-error': (charCounter && maxCounter && currentCount >= maxCounter) ?? error,
        'is-success': success,
    }).trim();
    const textareaClassNameList = classNames('c-input__input', {
        'is-error': (charCounter && maxCounter && currentCount >= maxCounter) ?? error,
        'is-success': success,
        [`${modifierStates} `]: modifierStates,
    }).trim();
    const counterClassNameList = classNames('c-input__counter', {
        'c-input__counter--alert': typeof maxCounter === 'number' ? currentCount >= maxCounter : false,
    }).trim();
    /**
     * Conditionally render an `[aria-describedby]` attribute,
     * where {@link error} with {@link errorMessage} takes precedence over {@link charCounter}.
     * @type {string|null}
     */
    // eslint-disable-next-line no-nested-ternary
    const describedByAttr = error && errorMessage ? `error-${id}` : charCounter ? `${id}-counter` : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: wrapperClassNameList },
            React.createElement("textarea", { "data-testid": testId, className: textareaClassNameList, id: id, cols: 30, rows: 10, onChange: (event) => {
                    charCounter && setCurrentCount(event.target.value.length);
                    return [onChange?.(event), context.setElementActive && context.setElementActive(!!event.target.value)];
                }, "aria-describedby": describedByAttr, ...(enableMaxLength && maxCounter ? { maxLength: maxCounter } : {}), ...otherProps, defaultValue: children })),
        charCounter && maxCounter && (React.createElement("div", { className: counterClassNameList, id: `${id}-counter`, "aria-live": "polite" },
            React.createElement("span", { className: "u-visually-hidden" }, "Text length:"),
            currentCount,
            React.createElement("span", { "aria-hidden": "true" }, "/"),
            React.createElement("span", { className: "u-visually-hidden" }, "of"),
            maxCounter,
            React.createElement("span", { className: "u-visually-hidden" }, "allowed characters"))),
        errorMessage));
}
