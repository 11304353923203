import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Wrapper component.
 * @todo Should be discussed whether at all it is obsolete and can be removed.
 * @constructor
 */
export function Wrapper({ children, pageWrapper = false }) {
    if (pageWrapper) {
        return React.createElement("div", { className: "o-main-container" }, children);
    }
    return React.createElement("main", null, children);
}
/**
 * Bronson Layout component.
 * @see https://bronson.vwfs.tools/default/docs/objects/layout.html
 * @constructor
 */
export const Layout = (props) => {
    const componentListClass = classNames('o-layout', {
        'o-layout--middle': props.middle,
        'o-layout--equal-height': props.equalHeight,
        'o-layout--center': props.center,
        'o-layout--right': props.right,
        'o-layout--flush': props.flush,
        'o-layout--divider': props.divider,
    }, props.className).trim();
    return React.createElement("div", { className: componentListClass }, props.children);
};
/**
 * Bronson LayoutItem component (nested).
 * @constructor
 */
export function LayoutItem(props) {
    const componentListClass = classNames('o-layout__item', {
        [`u-${props.default}`]: props.default,
        [`u-${props.xs}@xs`]: props.xs,
        [`u-${props.s}@s`]: props.s,
        [`u-${props.m}@m`]: props.m,
        [`u-${props.l}@l`]: props.l,
        [`u-${props.xl}@xl`]: props.xl,
    }, props.className).trim();
    return React.createElement("div", { className: componentListClass }, props.children);
}
LayoutItem.displayName = 'Layout.Item';
Layout.Item = LayoutItem;
