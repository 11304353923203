import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormContext } from '../Form/FormContext';
import { FormFieldContext } from './FormFieldContext';
/**
 * Values for {@link FormField.propTypes.type} prop.
 */
const typeValues = ['input', 'textarea', 'select', 'checkbox', 'other'];
/**
 * Bronson FormField component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-form-field.html
 * @constructor
 */
export function FormField({ children, className, errorMessage, extraContent, hint, infoIcon, inline, labelElement = 'label', labelForId, labelId, labelText, mark, noFloatingLabel, notion, onClick, staticLabel, type, testId, ...otherProps }) {
    const context = React.useContext(FormContext);
    const [elementActive, setElementActive] = useState(false);
    const contextValue = {
        setElementActive: (active) => setElementActive(active),
    };
    const formFloatingLabel = context && context.floatingLabel;
    useEffect(() => {
        if (!type) {
            const valueHint = typeValues.join(', ');
            if (formFloatingLabel) {
                // eslint-disable-next-line no-console
                console.error(`Warning: The prop \`type\` is required in \`<FormField>\` (possible values: ${valueHint}) because floating labels are enabled in \`<Form>\` element.`);
            }
            else {
                // eslint-disable-next-line no-console
                console.warn(`The prop \`type\` should be added to \`<FormField>\` (possible values: ${valueHint}). This warning has no negative effect because floating labels are not enabled in the \`<Form>\` element.`);
            }
        }
    });
    const standardLabel = 
    // <Form floatingLabel> prop not set
    !formFloatingLabel ||
        // no floating when explicitly set
        noFloatingLabel ||
        // downstream element is active (e.g. contains text, triggered via onChange in element on callback)
        elementActive ||
        // no floating if not in [input, textarea]
        (type !== 'input' && type !== 'textarea') ||
        // No floating with {@link InfoIcon}.
        infoIcon;
    const divClassNameList = classNames('c-form-field', {
        'c-form-field--no-floating': noFloatingLabel,
        'c-form-field--static': staticLabel,
        'c-form-field--textarea': type === 'textarea',
        'is-active': formFloatingLabel && standardLabel && !staticLabel,
        'c-form-field--inline': inline,
    }, className).trim();
    const CustomTagLabelElement = `${labelElement}`;
    function renderIfNotion() {
        if (notion) {
            return React.createElement("span", { className: "c-form-field__notion" },
                mark || '* ',
                " ");
        }
        return null;
    }
    function renderIfLabel() {
        if (labelText) {
            return (React.createElement(CustomTagLabelElement, { id: labelId, htmlFor: labelElement === 'label' && labelForId ? labelForId : undefined, className: "c-form-field__label", "aria-describedby": hint && `${labelForId}-hint` },
                labelText,
                renderIfNotion(),
                renderIfInfoIcon()));
        }
        return null;
    }
    function renderIfInfoIcon() {
        return infoIcon ? React.createElement(React.Fragment, null, infoIcon) : null;
    }
    function renderIfErrorMessage() {
        return errorMessage ? React.createElement(React.Fragment, null, errorMessage) : null;
    }
    function renderIfExtraContent() {
        if (extraContent) {
            return React.createElement("div", { className: "c-form-field__extra" }, extraContent);
        }
        return null;
    }
    function renderIfHint() {
        if (hint) {
            /**
             * If the {@link onClick} is set we use an {@link HTMLAnchorElement} as hint,
             * otherwise we fall back to {@link HTMLParagraphElement}.
             * @type {string}
             */
            const HintElement = onClick ? 'a' : 'p';
            return (React.createElement(HintElement, { className: "c-form-field__hint", id: `${labelForId}-hint`, "data-testid": testId && `${testId}-hint`, onClick: onClick }, hint));
        }
        return null;
    }
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList },
        renderIfLabel(),
        React.createElement("div", { className: "c-form-field__box" },
            React.createElement(FormFieldContext.Provider, { value: contextValue }, children),
            renderIfErrorMessage(),
            renderIfExtraContent()),
        renderIfHint()));
}
