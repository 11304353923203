import React, { isValidElement, useContext, useReducer } from 'react';
import classNames from 'classnames';
import { ProductSearchContext } from './ProductSearchContext';
import { productFilterReducer, productFilterReducerActions, } from './ProductSearchReducer';
export function ProductSearch({ children, sidebar, initialState, className, ...otherProps }) {
    const [state, dispatch] = useReducer(productFilterReducer, initialState);
    const sectionClassName = classNames('c-product-search', {
        'c-product-search--has-sidebar': sidebar,
    }, className).trim();
    return (React.createElement(ProductSearchContext.Provider, { value: { state, dispatch } },
        React.createElement("section", { className: sectionClassName, ...otherProps }, children)));
}
export function ProductSearchLayoutHeader({ children }) {
    return React.createElement("header", { className: "c-product-search__header" }, children);
}
export function ProductSearchLayoutSidebar({ children }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "c-product-search__sidebar" }, children)));
}
export function ProductSearchLayoutResults({ children }) {
    return React.createElement("div", { className: "c-product-search__results" }, children);
}
export function ProductSearchLayoutAside({ children }) {
    const { state, dispatch } = useContext(ProductSearchContext);
    return (React.createElement("div", { className: "c-product-search__aside" }, isValidElement(children) &&
        React.cloneElement(children, {
            isHidden: state.isInteractionLayerHidden,
            onClose: () => dispatch({ type: productFilterReducerActions.hideFilters }),
        })));
}
export function ProductSearchLayoutFooter({ children }) {
    return React.createElement("div", { className: "c-product-search__footer" }, children);
}
export const toFilterTags = ({ maxValue, minValue, unit = '', value }) => {
    const v = Array.isArray(value) ? value.join(', ') : value;
    if (v) {
        return `${v} ${unit}`;
    }
    if (maxValue) {
        if (minValue) {
            return `${minValue}–${maxValue} ${unit}`;
        }
        return `${maxValue} ${unit}`;
    }
    return '';
};
ProductSearchLayoutHeader.displayName = 'ProductSearch.LayoutHeader';
ProductSearch.LayoutHeader = ProductSearchLayoutHeader;
ProductSearchLayoutSidebar.displayName = 'ProductSearch.LayoutSidebar';
ProductSearch.LayoutSidebar = ProductSearchLayoutSidebar;
ProductSearchLayoutResults.displayName = 'ProductSearch.LayoutResults';
ProductSearch.LayoutResults = ProductSearchLayoutResults;
ProductSearchLayoutAside.displayName = 'ProductSearch.LayoutAside';
ProductSearch.LayoutAside = ProductSearchLayoutAside;
ProductSearchLayoutFooter.displayName = 'ProductSearch.LayoutFooter';
ProductSearch.LayoutFooter = ProductSearchLayoutFooter;
