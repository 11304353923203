import React, { useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import useIntersectionObserver from '../../features/useIntersectionObserver';
import { ScrollObserverCore, SCROLL_OBSERVER_SKIP_OBSERVE_ATTR, } from '../../features/ScrollObserver';
import useResizeObserver from '../../features/useResizeObserver';
/**
 * The Section Nav is an on-page navigation pattern to allow for quick navigation to certain
 * collated/grouped sections on the page. It also acts as an orientation pattern as it highlights
 * the currently most visible section inside its navigation list.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-section-nav.html
 * @constructor
 */
export function SectionNav({ button, className, children, defaultSectionLabel, 
/**
 * The overflowThreshold defines an additional amount of pixels
 * an element must pass before it is being considered to be
 * in the parent’s scroll overflow.
 */
overflowThreshold = 10, 
/**
 * Defines the time to wait after scrolling the list. Sometimes it
 * could be beneficial if a larger time is set in order make
 * the scrolling happen more prominently.
 */
autoScrollDelay = 250, scrollObserverTargetRef, scrollObserverSectionRefs, reveal, testId, ...otherProps }) {
    const [isActiveItem, setIsActiveItem] = useState(null);
    const [hasOverFlowLeft, setHasOverFlowLeft] = useState(false);
    const [hasOverFlowRight, setHasOverFlowRight] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const [isAutoScrolling, setIsAutoScrolling] = useState(null);
    const [label, setLabel] = useState(null);
    /**
     * Refs
     * @type {React.MutableRefObject<null>}
     */
    const sectionNavRef = useRef(null);
    const sectionNavContainerRef = useRef(null);
    const sectionNavListRef = useRef(null);
    const activeItemRef = useRef(null);
    const sectionNavClassNames = classNames('c-section-nav', { 'c-section-nav--reveal': reveal }, 'js-section-nav', 'js-scroll-observer', className).trim();
    const sectionNavListParentClassNames = classNames('c-section-nav__scroll', 'js-section-nav__scroll', {
        'has-overflow-left': hasOverFlowLeft,
        'has-overflow-right': hasOverFlowRight,
    }).trim();
    const sectionNavContainerClassNames = classNames('c-section-nav__container', 'js-section-nav__container', {
        'is-visible': isExpanded,
    }).trim();
    /**
     * Hook to disable scrolling the page when the scroll container is expanded on small viewports.
     */
    useEffect(() => {
        document.body.classList.toggle('c-section-nav-prevent-scroll', isExpanded);
    }, [isExpanded]);
    /**
     * Checks if the link is in the scroll list overflow under the following conditions:
     * [1] If the link is completely/partially in overflow on the right-hand side.
     * [2] If the link is completely/partially in overflow on the right-hand side.
     * @param {HTMLLinkElement} link
     * @param {HTMLUListElement} list
     * @return {boolean}
     */
    const checkLinkOverflow = (link, list) => {
        if (link && list) {
            return (link.offsetLeft - list.scrollLeft >= list.offsetWidth ||
                link.offsetLeft <= list.scrollLeft /* [2] */ ||
                link.offsetLeft + link.clientWidth >= list.offsetWidth); /* [2] */
        }
        return false;
    };
    /**
     * Checks if the scroll list has an overflow to either horizontal side.
     */
    const checkListOverflow = useCallback((element) => {
        const { clientWidth, scrollLeft, scrollWidth } = element;
        /**
         * If the target has a scroll overflow on the left-hand side.
         */
        if (scrollLeft > overflowThreshold) {
            setHasOverFlowLeft(true);
        }
        else {
            setHasOverFlowLeft(false);
        }
        /**
         * If the target has a scroll overflow on the right-hand side.
         */
        if (scrollWidth - clientWidth > scrollLeft) {
            setHasOverFlowRight(true);
        }
        else {
            setHasOverFlowRight(false);
        }
    }, [overflowThreshold]);
    /**
     * Update scrollOffset.
     */
    useEffect(() => {
        if (sectionNavListRef?.current) {
            const offset = parseFloat(window.getComputedStyle(sectionNavListRef?.current, '::before')?.width ?? 0);
            checkListOverflow(sectionNavListRef?.current);
            if (offset >= 0) {
                setScrollOffset(offset);
            }
        }
    }, [checkListOverflow, sectionNavListRef]);
    /**
     * Autoscroll an active item into view.
     */
    useEffect(() => {
        /**
         * Check if the list item is visible inside the scrollable list.
         * @type {boolean}
         */
        const listItemInOverflow = checkLinkOverflow(activeItemRef?.current, sectionNavListRef?.current);
        /**
         * Only scroll an item into the view that is activated, and
         * we are not in a scroll movement to avoid scroll cancellation
         * in Chrome.
         */
        if (activeItemRef?.current && listItemInOverflow) {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    sectionNavListRef?.current?.scrollBy({
                        top: 0,
                        left: (activeItemRef?.current?.offsetLeft ?? 0) -
                            (sectionNavListRef?.current?.scrollLeft ?? 0) -
                            (scrollOffset ?? 0),
                        behavior: 'smooth',
                    });
                });
            }, autoScrollDelay);
        }
    }, [isActiveItem, scrollOffset, activeItemRef, autoScrollDelay]);
    /**
     * Track the section container.
     */
    useIntersectionObserver({
        ref: sectionNavRef,
        target: scrollObserverTargetRef,
        onIntersectionHandler: ScrollObserverCore.observeEntries,
        opts: {
            intersectionObserverOpts: { rootMargin: '0px 0px -100%' },
            classes: ScrollObserverCore.classes,
        },
    });
    /**
     * Track the sections.
     */
    useIntersectionObserver({
        ref: sectionNavListRef,
        target: scrollObserverSectionRefs,
        onIntersectionHandler: ScrollObserverCore.observeEntries,
        opts: {
            intersectionObserverOpts: { rootMargin: '-49.99% 0px -50%', allowMultiple: true },
            classes: ScrollObserverCore.classes,
        },
        onMatch: ({ mostVisibleEntry }) => {
            const id = mostVisibleEntry?.target?.getAttribute('id');
            const content = document?.querySelector(`[href="#${id}"]`)?.textContent;
            if (id && isActiveItem !== id) {
                setIsActiveItem(id);
            }
            if (content) {
                setLabel(content);
            }
        },
    });
    /**
     * Resize callback
     * @type {(function([*]): void)|*}
     */
    const onResizeHandler = useCallback(([entry]) => {
        const breakpoint = parseFloat(getComputedStyle(entry.target).getPropertyValue('--bron-section-nav-breakpoint'));
        if (window.innerWidth > breakpoint) {
            if (isExpanded) {
                setIsExpanded(false);
            }
            checkListOverflow(sectionNavListRef?.current);
        }
    }, [checkListOverflow, isExpanded]);
    /**
     * Track resizing on the section nav.
     */
    useResizeObserver({
        ref: sectionNavRef,
        onResizeHandler,
    });
    /**
     * Handle clicks on the backdrop and close the mobile navigation.
     * @param {Event} event
     */
    const handleBackdropClick = useCallback((event) => {
        if (isExpanded && event?.target?.matches(`#${sectionNavContainerRef?.current?.id}`)) {
            setIsExpanded(false);
        }
    }, [isExpanded]);
    /**
     * Handle click/touch events on the section nav links.
     * If the website has an anchor navigation and the section id does not match
     * we do not scroll the list to prevent scroll cancelling on Chrome.
     * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1121151
     * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1043933
     * @see https://bugs.chromium.org/p/chromium/issues/detail?id=833617
     */
    const handleLinkClick = useCallback((textContent, id) => {
        setIsAutoScrolling(true);
        if (textContent) {
            setTimeout(() => {
                setIsAutoScrolling(null);
                setIsActiveItem(id);
            }, autoScrollDelay * 2);
        }
        if (isExpanded) {
            setIsExpanded(false);
        }
    }, [autoScrollDelay, isExpanded, setIsExpanded, setIsAutoScrolling]);
    /**
     * Scrolls the list by a fixed amount in either left or right direction.
     * The scroll is approximately 25% of the scroll list’s width each time.
     * @param {number} [direction=1] - The number designating the scroll direction, where 1 is left and -1 is right.
     */
    const scrollList = useCallback((direction = 1) => {
        requestAnimationFrame(() => {
            sectionNavListRef?.current?.scrollBy({
                top: 0,
                left: -(sectionNavListRef?.current?.offsetWidth ?? 0 / Math.abs((children?.length ?? 1) * 0.25)) * direction,
                behavior: 'smooth',
            });
        });
    }, [sectionNavListRef, children]);
    function renderSectionNavButton() {
        if (React.isValidElement(button)) {
            return React.cloneElement(button, {
                className: 'c-section-nav__btn',
            });
        }
        return null;
    }
    return (React.createElement("div", { className: sectionNavClassNames, ref: sectionNavRef, ...otherProps },
        React.createElement("div", { className: "c-section-nav__header" },
            React.createElement("button", { className: "c-section-nav__section-label  js-section-nav__section-label", type: "button", "aria-expanded": isExpanded, "aria-controls": "section-nav-container", onClick: () => {
                    setIsExpanded(!isExpanded);
                } },
                React.createElement("span", { className: "c-section-nav__section-label__text  js-section-nav__section-label__text" }, label ?? defaultSectionLabel)),
            renderSectionNavButton()),
        React.createElement("nav", { className: sectionNavContainerClassNames, id: "section-nav-container", "aria-label": "Table of contents", ref: sectionNavContainerRef, onClick: handleBackdropClick },
            React.createElement("div", { className: sectionNavListParentClassNames },
                React.createElement("button", { className: "c-section-nav__scroll-button  c-section-nav__scroll-button--prev  js-section-nav__scroll-button--prev", type: "button", tabIndex: -1, "aria-label": "Scroll to the left", onClick: () => scrollList(1) }),
                React.createElement("ul", { className: "c-section-nav__list  js-section-nav__list  js-scroll-observer", ref: sectionNavListRef, onScroll: (event) => checkListOverflow(event?.target), ...{ [SCROLL_OBSERVER_SKIP_OBSERVE_ATTR]: isAutoScrolling } }, React.Children.map(children, (child) => React.cloneElement(child, {
                    onClick: (event) => {
                        child?.props?.onClick(event);
                        handleLinkClick(event, child?.props.id);
                    },
                    isActive: isActiveItem === child?.props.id,
                    ref: child?.props.id === isActiveItem ? activeItemRef : null,
                }))),
                React.createElement("button", { className: "c-section-nav__scroll-button  c-section-nav__scroll-button--next  js-section-nav__scroll-button--next", type: "button", tabIndex: -1, "aria-label": "Scroll to the right", onClick: () => scrollList(-1) })),
            renderSectionNavButton())));
}
/**
 * Internal SectionNavItem component to render {@link SectionNav} items.
 *
 * @params {string} id
 * @params {boolean} isActive
 * @params {function} onClick
 * @params {string} anchor
 * @params {string} text
 * @params {string} className
 *
 */
const SectionNavItem = React.forwardRef(({ className, id, children, isActive, anchor, text, onClick = () => { } }, ref) => {
    const sectionNavLinkClassNames = classNames('c-section-nav__link', { 'is-active': isActive }, className, 'js-section-nav__link').trim();
    return (React.createElement("li", { className: "c-section-nav__item", ref: ref },
        React.createElement("a", { className: sectionNavLinkClassNames, href: `#${anchor}`, "data-text": text, onClick: (event) => onClick(event?.target?.textContent, id) }, children)));
});
SectionNav.Item = SectionNavItem;
SectionNav.Item.displayName = 'SectionNav.Item';
