import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * The SectionHeading component shows headings in a consistent manner.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-section-headings--section-headings.html
 */
export function SectionHeading({ alternative, center, children, className, icon, infoIcon, level, primary, smallSpacing, subtitle, superscript, titleSecondary, testId, ...otherProps /* in <header> tag */ }) {
    const headerClassNameList = classNames('c-section-heading', {
        'c-section-heading--center ': center,
        'c-section-heading--primary ': primary,
        'c-section-heading--small-spacing ': smallSpacing,
        'c-section-heading--alternative ': alternative,
    }, className).trim();
    // tag containing a variable or condition
    const CustomTagSectionHeadingsLevel = `h${level}`;
    const renderTitleIcon = () => {
        const iconClassNameList = classNames('c-section-heading__icon');
        return React.createElement(Icon, { className: iconClassNameList, name: icon });
    };
    function renderIfSectionHeadingsTitle() {
        if (children) {
            return (React.createElement(CustomTagSectionHeadingsLevel, { className: "c-section-heading__title" },
                icon && renderTitleIcon(),
                alternative && titleSecondary ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "c-section-heading__title-text" }, children),
                    React.createElement("span", { className: "c-section-heading__title-text u-heading-alternative" },
                        titleSecondary,
                        superscript && React.createElement("sup", null, superscript)))) : (React.createElement("span", { className: "c-section-heading__title-text" },
                    children,
                    superscript && React.createElement("sup", null, superscript))),
                infoIcon));
        }
        return null;
    }
    return (React.createElement("header", { className: headerClassNameList, "data-testid": testId, ...otherProps },
        renderIfSectionHeadingsTitle(),
        subtitle && React.createElement("p", { className: "c-section-heading__subtitle" }, subtitle)));
}
