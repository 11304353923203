import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson Wizard component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-wizard.html
 * @constructor
 */
export function Wizard({ children, className, collapseBreakpoint, collapsedCounter, collapsedText, collapsedTitle, defaultOpen = false, noInteraction, testId, ...otherProps }) {
    const [open, setOpen] = useState(defaultOpen);
    const navClassNameList = classNames('c-wizard', 'js-wizard', 'js-is-ready', // Used in CSS.
    {
        'c-wizard--no-interaction ': noInteraction, // Convenience prop from Bronson variants.
    }, className).trim();
    const olClassNameList = classNames('c-wizard__list', 'js-wizard__list', 'js-is-ready', {
        'is-closed': !open,
    }, className).trim();
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: navClassNameList, "data-wizard-collapse": collapseBreakpoint, "data-testid": testId, ...otherProps },
            React.createElement("button", { className: "c-wizard__trigger js-wizard__trigger", type: "button", "aria-expanded": open, title: collapsedTitle, onClick: () => setOpen(!open) },
                React.createElement(Icon, { className: "c-wizard__trigger-icon", viaCss: true }),
                collapsedText && React.createElement("span", { className: "c-wizard__trigger-text" }, collapsedText),
                collapsedCounter && React.createElement("span", { className: "c-wizard__trigger-counter" }, collapsedCounter)),
            React.createElement("ol", { className: olClassNameList }, children))));
}
/**
 * Bronson WizardItem component (nested).
 * @constructor
 */
function WizardItem({ active, children, className, disabled, done, error, hiddenCurrentStepText = 'Current Step', noInteraction, onClick, testId, ...otherProps }) {
    const liClassNameList = classNames('c-wizard__item', {
        'is-done': done,
        'is-active': active,
        'is-error': error,
        'is-disabled': disabled,
        'has-no-interaction': noInteraction,
    }, className).trim();
    function renderIfStateActive() {
        return active ? React.createElement("span", { className: "u-visually-hidden" },
            hiddenCurrentStepText,
            ":") : null;
    }
    return (React.createElement("li", { "data-testid": testId, className: liClassNameList },
        React.createElement("a", { className: "c-wizard__link", onClick: onClick, "aria-disabled": disabled, ...otherProps },
            renderIfStateActive(),
            React.createElement("span", { className: "c-wizard__link-title" }, children))));
}
WizardItem.displayName = 'Wizard.Item';
Wizard.Item = WizardItem;
