import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip/Tooltip';
/*
 * Bronson InfoIcon component.
 */
export function InfoIcon({ children, className, element = 'button', icon, tippyProps, testId, ...otherProps }) {
    const CustomElement = `${element}`;
    const buttonClassNameList = classNames({
        'c-icon ': true,
        'c-info-icon ': true,
        [`c-icon--[${icon}] `]: true,
    }, className).trim();
    const typeProp = element === 'button' ? { type: 'button' } : {};
    return (React.createElement(Tooltip, { content: children, ...tippyProps },
        React.createElement(CustomElement, { ...otherProps, "data-testid": testId, ...typeProp, className: buttonClassNameList, "data-tippy-content": children, "data-tippy-placement": children && 'top' })));
}
