import React, { useCallback, useEffect, useRef, useState, } from 'react';
import classNames from 'classnames';
import { BasicSlider, useBasicSlider } from '../BasicSlider/BasicSlider';
import { PriceBox } from '../PriceBox/PriceBox';
import { Button } from '../Button/Button';
import { useMatchMedia } from '../../features/useMatchMedia';
import { Icon } from '../Icon/Icon';
import useResizeObserver from '../../features/useResizeObserver';
import getTextContent from '../../utils/getTextContent';
/**
 * Bronson CompareAndSelect component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-comapre-and-select.html
 * @constructor
 */
export const CompareAndSelect = ({ buttonVariant = false, children, defaultSelected, deselectable, inputGroup, onChange, preserveHeight, radioButtonSection, sliderCounter, stacked, testId, ...otherProps }) => {
    const [flickityRef, setFlickityRef] = useState(null);
    const [selected, setSelected] = useState(defaultSelected);
    const initialResizeThreshold = 250;
    const compareSelectAccordionBreakpointCustomProp = '--bron-compare-select-accordion-breakpoint';
    const [breakpoint, setBreakpoint] = useState(null);
    const matchBreakpoint = useMatchMedia(`(min-width: ${breakpoint})`);
    const stackedElementRef = useRef(null);
    const elementRef = useRef(null);
    /**
     * Add the ability to reset the {@link defaultSelected} prop via side effect.
     * Only allow resetting when external deselection is turned on via {@link deselectable}.
     */
    useEffect(() => {
        if (deselectable) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected, deselectable]);
    /**
     * Handles {@link flickityRef} and {@link stackedElementRef} depending on the
     * current breakpoint when {@link stacked}.
     * Get the current breakpoint for {@link compareSelectAccordionBreakpointCustomProp}
     * and set it as {@link breakpoint}.
     */
    useEffect(() => {
        const refElement = matchBreakpoint ? flickityRef?.element : stackedElementRef?.current;
        /**
         * Cleanup function to prevent the internal state of the component
         * from being updated when it is unmounted.
         */
        const timer = setTimeout(() => {
            if (refElement && !breakpoint) {
                const currentBreakpoint = getComputedStyle(refElement).getPropertyValue(compareSelectAccordionBreakpointCustomProp);
                setBreakpoint(currentBreakpoint);
            }
        }, 0);
        return () => clearTimeout(timer);
    }, [flickityRef, stackedElementRef, breakpoint, matchBreakpoint]);
    useEffect(() => {
        if (process.env.NODE_ENV !== 'production' && deselectable && radioButtonSection) {
            throw new Error('A radio button group cannot be deselectable.');
        }
    }, [deselectable, radioButtonSection]);
    /**
     * Establish equal heights for items.
     * @type {(function(): void)|*}
     */
    const handleEqualHeights = useCallback(() => {
        if (!preserveHeight) {
            return;
        }
        setTimeout(() => {
            const headers = Array.from(elementRef?.current?.querySelectorAll('.c-compare-and-select__title') ?? []) ?? [];
            const subheaders = Array.from(elementRef?.current?.querySelectorAll('.c-compare-and-select__pricing') ?? []) ?? [];
            headers?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', 'initial'));
            subheaders?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', 'initial'));
            const [maxHeightTitle] = headers?.map((h) => h?.clientHeight).sort((a, b) => (a > b ? -1 : 1)) ?? [];
            const [maxHeightSubTitles] = subheaders?.map((h) => h?.clientHeight).sort((a, b) => (a > b ? -1 : 1)) ?? [];
            if (maxHeightTitle) {
                headers?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', `${maxHeightTitle}px`));
            }
            if (maxHeightSubTitles) {
                subheaders?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', `${maxHeightSubTitles}px`));
            }
            flickityRef && flickityRef.resize();
        }, 100);
    }, [flickityRef, preserveHeight]);
    useEffect(() => {
        handleEqualHeights();
    }, [elementRef, handleEqualHeights]);
    /**
     * Track resizing on the {@link elementRef} and call the equal height callback.
     */
    useResizeObserver({
        ref: elementRef,
        onResizeHandler: () => {
            handleEqualHeights();
        },
    });
    const updateSelected = (newSelected) => {
        if (newSelected === selected) {
            if (deselectable && !radioButtonSection) {
                // eslint-disable-next-line no-param-reassign
                newSelected = undefined;
            }
        }
        setSelected(newSelected);
        if (onChange) {
            onChange(newSelected);
        }
        flickityRef && flickityRef.resize();
    };
    useBasicSlider(flickityRef, children, {
        initialIndex: defaultSelected,
        selected,
        hasNestedMutations: false,
        initialResizeThreshold,
    });
    const flickityOptions = {
        cellSelector: '.js-compare-and-select__item',
        cellAlign: 'center',
        groupCells: '100%',
        initialIndex: defaultSelected,
    };
    const flickityClassNameList = classNames('c-compare-and-select-container__inner', 'js-compare-and-select', 'is-initialized', {
        'c-compare-and-select--stacked': stacked,
    }).trim();
    /* Use 'CompareAndSelect.Item' component. */
    const compareAndSelectItems = () => React.Children.map(children, (child, index) => {
        if (!child) {
            return null;
        }
        return React.cloneElement(child, {
            selected: selected === index,
            updateSelected,
            index,
            buttonVariant,
            radioButtonSection,
            inputGroup,
            stacked,
            matchBreakpoint,
        });
    });
    function renderStacked() {
        return (React.createElement("div", { className: "c-compare-and-select-container__inner  c-compare-and-select--stacked", ref: stackedElementRef }, compareAndSelectItems()));
    }
    function renderSlider() {
        return (React.createElement(BasicSlider, { elementType: "div", className: flickityClassNameList, flickityRef: (c) => setFlickityRef(c), options: flickityOptions, sliderCounter: sliderCounter }, compareAndSelectItems()));
    }
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: "c-compare-and-select-container", ref: elementRef }, 
    /**
     * Mounts {@link BasicSlider} only if the media query
     * in {@link matchBreakpoint} matches when {@link stacked}.
     */
    stacked && !matchBreakpoint ? renderStacked() : renderSlider()));
};
/**
 * Bronson CompareAndSelectItem component (nested).
 * Render a single {@link CompareAndSelect} children item.
 * @internal
 * @constructor
 */
export function CompareAndSelectItem({ ariaLabelDiscountOldPrice, ariaLabelDiscountValue, ariaLabelPrice, buttonLabel, buttonVariant, checkbox, children: itemChildren, className, description, descriptionClassName, descriptionInfoIcon, descriptionLinkHref, descriptionLinkLabel, descriptionTitle, detailsButtonLabel, disableButton, discountOldPrice, discountValue, flexibleButton, icon, index, indicatorTitle, indicatorTitleAttr = undefined, infoIcon, inputAttributes, inputGroup, label, legal, legalTitle, matchBreakpoint, price, pricingAdditionalInfo, pricingHeader, radioButtonSection, radioDescription, radioInfoIcon, radioValue, selected, stacked, title, titleAttr = undefined, truncateTitle, updateSelected, testId, ...otherProps }) {
    const priceValue = price;
    const priceHeader = pricingHeader ? (React.createElement(React.Fragment, null,
        pricingHeader,
        infoIcon)) : null;
    const CSSOverrides = {
        '--transition-duration': 0,
        '--transition-property': 'none',
    };
    const itemClassNameList = classNames('c-compare-and-select__item', 'js-compare-and-select__item', className).trim();
    const inputClassNameList = classNames('c-compare-and-select__hidden-input', {
        'c-radio__input': radioButtonSection,
    }).trim();
    const divClassNameList = classNames('c-compare-and-select', {
        'c-compare-and-select--selected': buttonVariant && selected,
        'c-radio__label-container': radioButtonSection,
    }).trim();
    const iClassNameList = classNames({
        'c-compare-and-select__icon': icon,
    }).trim();
    const descriptionClassNameList = classNames('c-compare-and-select__description', descriptionClassName).trim();
    const titleTextClassNameList = classNames('c-compare-and-select__title-text', {
        'u-text-truncate': truncateTitle,
    }).trim();
    const renderCompareAndSelectButton = () => (React.createElement(Button, { onClick: () => updateSelected?.(index), className: "c-compare-and-select__button", type: "button", disabled: disableButton }, buttonLabel));
    const renderCompareAndSelectLabel = () => (React.createElement(React.Fragment, null,
        React.createElement("label", { className: "c-compare-and-select__button c-btn", htmlFor: `${inputGroup}-${index}`, onClick: () => updateSelected?.(index), 
            // @ts-ignore @FIXME: Remove unsupported attribute, @see BRON-11872.
            disabled: disableButton }, buttonLabel)));
    function renderUnlessButtonVariant() {
        if (!buttonVariant) {
            return (React.createElement("input", { className: inputClassNameList, type: "radio", name: inputGroup, id: `${inputGroup}-${index}`, checked: selected, readOnly: true, ...inputAttributes }));
        }
        return null;
    }
    function renderIfLabel() {
        return label && React.createElement("div", { className: "c-compare-and-select__label" }, label);
    }
    function renderIfIcon() {
        if (icon) {
            return React.createElement(Icon, { className: iClassNameList, name: icon });
        }
        return null;
    }
    function renderIfValue() {
        if (priceValue) {
            return (React.createElement("div", { className: "c-compare-and-select__pricing" },
                React.createElement(PriceBox, { header: priceHeader, ariaLabelDiscountValue: ariaLabelDiscountValue || 'Discount:', discountValue: discountValue, ariaLabelDiscountOldPrice: ariaLabelDiscountOldPrice || 'Old Price:', discountOldPrice: discountOldPrice, ariaLabelPrice: ariaLabelPrice || 'Discount Price:', price: priceValue, additionalInfo: pricingAdditionalInfo })));
        }
        return null;
    }
    function renderIfDetails() {
        if (stacked && itemChildren) {
            return (React.createElement("details", { className: "c-compare-and-select__details  js-compare-and-select__details", open: matchBreakpoint ? true : undefined },
                React.createElement("summary", { className: "c-compare-and-select__summary" },
                    React.createElement(Button, { link: true, small: true, simple: true, icon: "semantic-expand", element: "span" }, detailsButtonLabel)),
                renderIfChildren()));
        }
        return null;
    }
    function renderIfChildren() {
        if (itemChildren) {
            return (React.createElement("ul", { className: "c-compare-and-select__list c-icon-list c-icon-list--small" }, itemChildren /* Use 'SelectAndCompare.CompareAndSelect.IconListItem' component. */));
        }
        return null;
    }
    function renderIfLinkInDescription() {
        if (descriptionLinkHref && descriptionLinkLabel) {
            return React.createElement("a", { href: descriptionLinkHref }, descriptionLinkLabel);
        }
        return null;
    }
    function renderIfDescription() {
        if (description) {
            return (React.createElement("p", { className: descriptionClassNameList },
                descriptionTitle && (React.createElement(React.Fragment, null,
                    React.createElement("strong", null, descriptionTitle),
                    ":",
                    ' ')),
                description,
                renderIfLinkInDescription(),
                descriptionInfoIcon));
        }
        return null;
    }
    function renderIfButton() {
        if (!flexibleButton && buttonLabel) {
            return React.createElement(React.Fragment, null, buttonVariant ? renderCompareAndSelectButton() : renderCompareAndSelectLabel());
        }
        return null;
    }
    function renderIfFlexibleButton() {
        if (flexibleButton && buttonLabel) {
            return React.createElement(React.Fragment, null, buttonVariant ? renderCompareAndSelectButton() : renderCompareAndSelectLabel());
        }
        return null;
    }
    function renderIfCheckbox() {
        if (checkbox) {
            return React.createElement("div", { className: "c-compare-and-select__checkbox" }, checkbox);
        }
        return null;
    }
    function renderIfLegal() {
        if (legal) {
            return (React.createElement("p", { className: "c-compare-and-select__legal" },
                legalTitle && (React.createElement(React.Fragment, null,
                    React.createElement("strong", null,
                        legalTitle,
                        ":"),
                    ' ')),
                legal));
        }
        return null;
    }
    function renderIfRadioButtonSection() {
        if (radioButtonSection) {
            return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            React.createElement("label", { className: "c-radio c-compare-and-select__radio", htmlFor: `${inputGroup}-${index}`, onClick: (e) => e.target.className.search('info-icon') === -1 && updateSelected?.(index) },
                React.createElement("span", { className: "c-radio__label c-compare-and-select__radio-label", checked: buttonVariant && selected },
                    radioValue,
                    radioInfoIcon,
                    React.createElement("span", { className: "c-compare-and-select__radio-description" }, radioDescription))));
        }
        return null;
    }
    function renderIfIndicatorTitle() {
        if (indicatorTitle) {
            return (React.createElement("h3", { className: "c-compare-and-select__title-indicator-text", title: indicatorTitleAttr ?? indicatorTitle }, indicatorTitle));
        }
        return null;
    }
    return (React.createElement("div", { ...otherProps, className: itemClassNameList, "data-testid": testId },
        renderUnlessButtonVariant(),
        React.createElement("div", { className: divClassNameList, style: CSSOverrides },
            renderIfLabel(),
            React.createElement("header", { className: "c-compare-and-select__title" },
                renderIfIcon(),
                renderIfIndicatorTitle(),
                React.createElement("h4", { className: titleTextClassNameList, title: titleAttr ?? getTextContent(title) }, title)),
            renderIfValue() /* PriceBox */,
            renderIfFlexibleButton() /* Flexible Button */,
            stacked ? renderIfDetails() /* Stacked */ : renderIfChildren() /* IconList */,
            renderIfDescription(),
            React.createElement("footer", { className: "c-compare-and-select__footer" },
                renderIfButton(),
                renderIfCheckbox(),
                renderIfLegal(),
                renderIfRadioButtonSection()))));
}
CompareAndSelect.displayName = 'CompareAndSelect.Item';
CompareAndSelect.Item = CompareAndSelectItem;
/**
 * Bronson IconListItem component (nested).
 * Render a decorative icon for the icon list.
 * @internal
 * @constructor
 */
function IconListItem({ icon, iconDescription, children, ...otherProps }) {
    const iClassNameList = classNames('c-icon-list__icon').trim();
    return (React.createElement("li", { ...otherProps },
        React.createElement(Icon, { className: iClassNameList, name: icon }),
        React.createElement("span", { className: "u-visually-hidden" }, `${iconDescription}: `),
        children));
}
IconListItem.displayName = 'CompareAndSelect.IconListItem';
CompareAndSelect.IconListItem = IconListItem;
