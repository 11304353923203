import React from 'react';
/*
 * Bronson RadioButtonGroup component.
 */
export function RadioButtonGroup({ children, testId, ...otherProps }) {
    function renderChildren() {
        return React.Children.map(children, (child) => {
            if (!child) {
                return null;
            }
            return (React.createElement("div", { ...otherProps, className: "o-inline-group__item" }, child));
        });
    }
    // generated main result
    return (React.createElement("fieldset", { ...otherProps, "data-testid": testId },
        React.createElement("div", { className: "o-inline-group" }, renderChildren())));
}
