import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Tag component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-tag.html
 * @constructor
 */
export function Tag({ children, className, close, closeLabel = 'Close', error, info, negative, onCloseClick, positive, success, warning, testId, ...otherProps }) {
    const spanClassNameList = classNames('c-tag', {
        // main class modifier convenience prop extension
        'c-tag--success': success,
        'c-tag--warning': warning,
        'c-tag--error': error,
        'c-tag--info': info,
        'c-tag--positive': positive,
        'c-tag--negative': negative, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderIfClose() {
        if (close) {
            return (React.createElement("button", { type: "button", className: "c-tag__close", title: closeLabel, "aria-label": closeLabel, onClick: onCloseClick }));
        }
        return null;
    }
    return (React.createElement("span", { ...otherProps, "data-testid": testId, className: spanClassNameList },
        React.createElement("span", { className: "c-tag__label" }, children),
        renderIfClose()));
}
