import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Checkbox component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-checkbox.html
 *
 * @constructor
 */
export function Checkbox({ children, className, error, id, testId, ...otherProps }) {
    const labelClassNameList = classNames('c-checkbox', className).trim();
    const inputClassNameList = classNames('c-checkbox__input', {
        'is-error ': error,
    }).trim();
    return (React.createElement("label", { className: labelClassNameList, htmlFor: id },
        React.createElement("input", { "data-testid": testId, className: inputClassNameList, type: "checkbox", id: id, ...otherProps }),
        React.createElement("span", { className: "c-checkbox__label" }, children)));
}
