import React from 'react';
import classNames from 'classnames';
/*
 * Bronson Pagination component.
 */
export function Pagination({ children, nextDisabled, nextText, onNextClick, onPrevClick, prevDisabled, prevText, title, testId, ...otherProps }) {
    const aClassNameList = classNames({
        'c-pagination__link ': true,
        'c-pagination__link--stepper-prev ': true,
        'is-disabled ': prevDisabled,
    }).trim();
    const aClassNameList2 = classNames({
        'c-pagination__link ': true,
        'c-pagination__link--stepper-next ': true,
        'is-disabled ': nextDisabled,
    }).trim();
    function renderIfNotPrevDisabled() {
        return prevDisabled ? null : (React.createElement("a", { href: "#", className: aClassNameList, title: prevText, onClick: onPrevClick },
            React.createElement("span", { className: "u-visually-hidden" }, prevText)));
    }
    function renderIfPrevDisabled() {
        return prevDisabled ? (React.createElement("span", { className: aClassNameList, title: prevText },
            React.createElement("span", { className: "u-visually-hidden" }, prevText))) : null;
    }
    function renderIfNotNextDisabled() {
        return nextDisabled ? null : (React.createElement("a", { href: "#", onClick: onNextClick, className: aClassNameList2, title: nextText },
            React.createElement("span", { className: "u-visually-hidden" }, nextText)));
    }
    function renderIfNextDisabled() {
        return nextDisabled ? (React.createElement("span", { className: aClassNameList2, title: nextText },
            React.createElement("span", { className: "u-visually-hidden" }, nextText))) : null;
    }
    return (React.createElement("nav", { ...otherProps, "data-testid": testId, className: "c-pagination", "aria-label": title },
        React.createElement("ul", { className: "c-pagination__list" },
            React.createElement("li", { className: "c-pagination__item" },
                renderIfNotPrevDisabled(),
                renderIfPrevDisabled()),
            children /* Use 'Pagination.Item' component. */,
            React.createElement("li", { className: "c-pagination__item" },
                renderIfNotNextDisabled(),
                renderIfNextDisabled()))));
}
/*
 * Bronson List component (nested).
 */
function PaginationItem({ active, children, disabled, onClick, ...otherProps /* in <li> tag */ }) {
    // generated
    const aClassNameList = classNames({
        'c-pagination__link ': true,
        'is-active ': active,
        'is-disabled ': disabled,
    }).trim();
    function renderIfNotActiveAndNotDisabled() {
        return !active && !disabled ? (React.createElement("a", { href: "#", onClick: onClick, className: aClassNameList }, children)) : null;
    }
    function renderIfActiveOrDisabled() {
        return active || disabled ? (React.createElement("span", { className: aClassNameList, "aria-current": active ? 'page' : false }, children)) : null;
    }
    return (React.createElement("li", { ...otherProps, className: "c-pagination__item" },
        renderIfNotActiveAndNotDisabled(),
        renderIfActiveOrDisabled()));
}
PaginationItem.displayName = 'Pagination.Item';
Pagination.Item = PaginationItem;
