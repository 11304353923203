import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { ReactLink } from '../ReactLink/ReactLink';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import { Tooltip } from '../Tooltip/Tooltip';
import { TableButton, TableButtonSorting } from '../Table/TableButton';
import { Form } from '../Form/Form';
/**
 * Default localizations for selected locales, which can be passed as locale prop.
 * Note: A localization object can be passed instead with the localization prop.
 */
const localizationDefault = {
    de: {
        'month.01.name': 'Januar',
        'month.01.name.short': 'jan',
        'month.02.name': 'Februar',
        'month.02.name.short': 'feb',
        'month.03.name': 'März',
        'month.03.name.short': 'mär',
        'month.04.name': 'April',
        'month.04.name.short': 'apr',
        'month.05.name': 'Mai',
        'month.05.name.short': 'mai',
        'month.06.name': 'Juni',
        'month.06.name.short': 'jun',
        'month.07.name': 'Juli',
        'month.07.name.short': 'jul',
        'month.08.name': 'August',
        'month.08.name.short': 'aug',
        'month.09.name': 'September',
        'month.09.name.short': 'sep',
        'month.10.name': 'Oktober',
        'month.10.name.short': 'okt',
        'month.11.name': 'November',
        'month.11.name.short': 'nov',
        'month.12.name': 'Dezember',
        'month.12.name.short': 'dez',
    },
};
const InboxContext = React.createContext({});
/**
 * Bronson Inbox component.
 * @see https://bronson.vwfs.tools/bluelabel/components/detail/bronson-inbox.html
 * @constructor
 */
export const Inbox = ({ children, columns = ['colDate', 'colDownload', 'colSubject', 'colDelete'], dateFormatter, deleteButtonLabel = 'Delete', deleteShowLabel = false, downloadButtonLabel = 'Download', downloadShowLabel = false, filterElements, filterTitle, locale = 'de', localization, sortingOrder = 'ASC', tableColumnClassNames, tableHeader, tableHeaderClassNames, testId, ...otherProps }) => {
    const [ascSorting, setAscSorting] = useState(sortingOrder === 'ASC');
    const contextValue = {
        localization: localization ?? localizationDefault[locale],
        tableHeader,
        tableHeaderClassNames,
        tableColumnClassNames,
        columns,
        dateFormatter,
        downloadButtonLabel,
        downloadShowLabel,
        deleteButtonLabel,
        deleteShowLabel,
        ascSorting,
        toggleSorting: () => setAscSorting((s) => !s),
    };
    const sortedSections = useMemo(() => {
        // @ts-ignore @FIXME: Fix sort args.
        const sections = React.Children.toArray(children).sort(({ props: a }, { props: b }) => {
            const numA = Number(a.year);
            const numB = Number(b.year);
            if (ascSorting) {
                return numA - numB;
            }
            return numB - numA;
        });
        return sections.map((section, i) => {
            if (i === 0) {
                return React.cloneElement(section, { showTableHeader: true, showYear: false });
            }
            return React.cloneElement(section, { showTableHeader: false, showYear: true });
        });
    }, [children, ascSorting]);
    return (React.createElement(React.Fragment, null,
        filterTitle || filterElements ? (React.createElement("div", { className: "c-inbox-filter" },
            filterTitle && React.createElement("h3", { className: "c-inbox-filter__title" }, filterTitle),
            filterElements && (React.createElement("div", { className: "c-inbox-filter__body" },
                React.createElement(Form, { floatingLabel: true },
                    React.createElement("div", { className: "o-inline-group o-inline-group--small o-inline-group--stretch" }, filterElements)))))) : (React.createElement(React.Fragment, null)),
        React.createElement("div", { className: "c-inbox-table", "data-testid": testId, ...otherProps },
            React.createElement(InboxContext.Provider, { value: contextValue }, sortedSections /* Use 'Inbox.Section' component. */))));
};
Inbox.defaultProps = {
    sortingOrder: 'ASC',
};
/**
 * Bronson FilterElement component (nested).
 * @param {string} [children]
 * @param {string} [testId]
 * @param {object} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function FilterElement({ children, testId, ...otherProps /* in <div> tag */ }) {
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: "o-inline-group__item" }, children));
}
FilterElement.displayName = 'Inbox.FilterElement';
Inbox.FilterElement = FilterElement;
// TODO: finish JSDoc
// TODO: move showYear to Inbox
// TODO: rename showTableHeader to first and use only internally
/**
 * Bronson Section component (nested).
 *
 * @param {React.ReactNodeArray} [children] - array of {@link Inbox.Message}
 * @param {boolean} [showTableHeader]
 * @param {boolean} [showYear]
 * @param {string} [year]
 * @param {string} [testId]
 * @param {object} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function Section({ children, showTableHeader, showYear, year, testId, ...otherProps /* in no tag */ }) {
    const context = React.useContext(InboxContext);
    const thClassNames = (colName = '') => classNames('c-inbox-table__th', context.tableHeaderClassNames?.[colName]).trim();
    const renderTh = (item, index) => {
        if (context.columns?.[index] === 'colDate') {
            return (React.createElement("th", { scope: "col", key: index, className: thClassNames(context.columns[index]) },
                React.createElement(TableButton, { sort: context.ascSorting ? TableButtonSorting.ASC : TableButtonSorting.DESC, onClick: context.toggleSorting }, item)));
        }
        return (React.createElement("th", { scope: "col", key: index, className: thClassNames(context.columns?.[index]) }, item));
    };
    // generated
    function renderIfShowYear() {
        if (showYear) {
            return React.createElement("h4", { className: "c-inbox-table__subheading" }, year);
        }
        return null;
    }
    // generated
    function renderIfShowTableHeader() {
        if (showTableHeader && context.tableHeader) {
            return (React.createElement("thead", null,
                React.createElement("tr", null, context.tableHeader.map(renderTh))));
        }
        return null;
    }
    /**
     * sorts the children according to sorting prop (@link context.ascSorting)
     */
    const sortedMessages = useMemo(() => {
        // @ts-ignore @FIXME: Fix sort args.
        return React.Children.toArray(children).sort(({ props: a }, { props: b }) => {
            // eslint-disable-next-line no-bitwise
            const numA = a.year * 10000 + a.month * 100 + (a.day >> 0);
            // eslint-disable-next-line no-bitwise
            const numB = b.year * 10000 + b.month * 100 + (b.day >> 0);
            if (context.ascSorting) {
                return numA - numB;
            }
            return numB - numA;
        });
    }, [children, context.ascSorting]);
    // generated main result
    return (React.createElement(React.Fragment, null,
        renderIfShowYear(),
        React.createElement("div", { className: "c-table-wrapper", "data-testid": testId, ...otherProps },
            React.createElement("table", null,
                renderIfShowTableHeader(),
                React.createElement("tbody", { className: "c-inbox-table__body" }, /* Use 'Inbox.Message' component. */ sortedMessages)))));
}
Section.displayName = 'Inbox.Section';
Inbox.Section = Section;
/**
 * Bronson Message component (nested).
 *
 * @param {React.ReactNode} [children]
 * @param {string} [day]
 * @param {string} [download]
 * @param {string} [extraInfo]
 * @param {string} [messageKey]
 * @param {string} [month]
 * @param {string} [onDeleteClick]
 * @param {string} [onDownloadClick]
 * @param {string} [onItemClick]
 * @param {string} [read]
 * @param {string} [to]
 * @param {string} [tooltipContent='Unread item']
 * @param {string} [year]
 * @param {string} [testId]
 * @param {string} [otherProps]
 * @return {JSX.Element}
 * @constructor
 */
function Message({ children, day, download, extraInfo, messageKey, month, onDeleteClick, onDownloadClick, onItemClick, read, to, tooltipContent = 'Unread item', year, testId, ...otherProps /* in <tr> tag */ }) {
    const context = React.useContext(InboxContext);
    const tdClassNames = (classes, colName) => classNames('c-inbox-table__td', classes, context.tableColumnClassNames && context.tableColumnClassNames[colName]).trim();
    const restProps = { ...otherProps };
    context.columns?.forEach((col) => delete restProps[col]);
    const monthKey = `month.${month}.name`;
    const monthKeyShort = `month.${month}.name`;
    const monthName = context.localization ? context.localization[monthKey] : null;
    const monthNameShort = context.localization ? context.localization[monthKeyShort] : null;
    const dateFormatter = context.dateFormatter || ((dayName) => `${dayName}. ${monthName}`);
    // generated
    const trClassNameList = classNames({
        'c-inbox-table__tr ': true,
        'is-unread ': !read,
    }).trim();
    // generated
    function renderIfDownload() {
        if (download) {
            return (React.createElement(Button, { element: "a", icon: "semantic-download", secondary: true, hiddenLabel: !context.downloadShowLabel, className: "c-table__btn", onClick: onDownloadClick, title: context.downloadButtonLabel }, context.downloadButtonLabel));
        }
        return null;
    }
    // generated
    function renderIfExtraInfo() {
        if (extraInfo) {
            return React.createElement("p", { className: "c-inbox-table__extra-info" }, extraInfo);
        }
        return null;
    }
    // generated
    function renderUnlessDownload() {
        if (!download) {
            return (React.createElement(Button, { icon: "semantic-delete", secondary: true, className: "c-table__btn", hiddenLabel: !context.deleteShowLabel, onClick: onDeleteClick, title: context.deleteButtonLabel }, context.deleteButtonLabel));
        }
        return null;
    }
    const renderColDate = (colName) => (React.createElement("td", { className: tdClassNames('c-inbox-table__td--date', colName), key: `${messageKey}--date` },
        React.createElement("time", { className: "c-inbox-table__date", "data-inbox-day": day, "data-inbox-month": monthNameShort || month, dateTime: `${year}-${month}-${day}`, title: `${day}. ${monthName || month} ${year}` }, dateFormatter(day, month, year))));
    const renderColDownload = (colName) => (React.createElement("td", { className: tdClassNames('c-inbox-table__td--action', colName), key: `${messageKey}--download` }, renderIfDownload()));
    const renderColDelete = (colName) => (React.createElement("td", { className: tdClassNames('c-inbox-table__td--action', colName), key: `${messageKey}--delete` }, renderUnlessDownload()));
    const renderColSubject = (colName) => (React.createElement("td", { className: tdClassNames('c-inbox-table__td--subject', colName), key: `${messageKey}--subject` },
        renderIfExtraInfo(),
        React.createElement("div", { className: "c-inbox-table__td__subject" },
            !read && (React.createElement(IndicatorDot, { className: "c-inbox-table__status-indicator", status: "unread", tooltip: React.createElement(Tooltip, { className: "c-indicator-dot__dot", content: tooltipContent, "aria-label": tooltipContent }, ' ') })),
            React.createElement(ReactLink, { onClick: onItemClick, to: to || '#', className: "c-inbox-table__link" }, children))));
    // generated main result
    return (React.createElement("tr", { ...restProps, "data-testid": testId, className: trClassNameList }, context.columns?.map((colName) => {
        switch (colName) {
            case 'colDate':
                return renderColDate(colName);
            case 'colDownload':
                return renderColDownload(colName);
            case 'colSubject':
                return renderColSubject(colName);
            case 'colDelete':
                return renderColDelete(colName);
            default:
                // @ts-ignore @FIXME: Refactor component to reuse header message via context.columns and TableHeader.
                return otherProps[colName] ? (React.createElement("td", { className: tdClassNames('u-hide@s', colName), key: `${messageKey}--${colName}` }, otherProps[colName])) : null;
        }
    })));
}
Message.displayName = 'Inbox.Message';
Inbox.Message = Message;
