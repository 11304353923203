/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useContext, createContext, useRef, useState } from 'react';
import classNames from 'classnames';
import useIntersectionObserver from '../../features/useIntersectionObserver';
import { ScrollObserverCore } from '../../features/ScrollObserver';
const TableContext = createContext({});
const TheadOrTbodyContext = createContext({});
const trackingRowStyle = {
    position: 'absolute',
    width: '100%',
    height: '0',
    top: 0,
    left: 0,
};
const trackingColumnStyle = {
    position: 'absolute',
    width: '0',
    height: '100%',
    left: 0,
    top: 0,
};
/**
 * Bronson Table component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-table--table.html
 * @constructor
 */
export const Table = ({ auto, bordered, children, className, colored, dataControls, highlight, layoutFixed, narrow, noHover, noScroll, responsive, stickyColumn, stickyHead, tableHeader, wide, testId, ...otherProps }) => {
    const tableWrapperRef = useRef(null);
    /**
     * Implement the sticky table behavior by utilizing two tracking elements
     * that signals if the table has either an intersecting header row or first column.
     * This deviates from how Bronson implements this (by tracking individual cells).
     * It also does not implement calculating parent and grandparent node offsets.
     */
    const stickColumnRef = useRef(null);
    const stickRowRef = useRef(null);
    /**
     * The state is tracked for either direction individually.
     */
    const [hasStickyHead, setHasStickyHead] = useState(false);
    const [hasStickyColumn, setHasStickyColumn] = useState(false);
    /**
     * Track the header row tracking element.
     */
    useIntersectionObserver({
        ref: tableWrapperRef,
        target: stickRowRef,
        onIntersectionHandler: () => ([entry]) => setHasStickyHead(!entry?.isIntersecting),
        opts: {
            classes: ScrollObserverCore.classes,
            intersectionObserverOpts: { rootMargin: '0px', threshold: [0, 1] },
        },
    });
    /**
     * Track the column tracking element.
     */
    useIntersectionObserver({
        ref: tableWrapperRef,
        target: stickColumnRef,
        onIntersectionHandler: () => ([entry]) => setHasStickyColumn(!entry?.isIntersecting),
        opts: {
            classes: ScrollObserverCore.classes,
            intersectionObserverOpts: { rootMargin: '0px', threshold: [0, 1] },
        },
    });
    const classNameList = classNames('c-table-wrapper', {
        'c-table-wrapper--auto': auto,
        'c-table-wrapper--no-scroll': noScroll,
        'c-table--bordered': bordered,
        'c-table--colored': colored,
        'c-table--data-controls': dataControls,
        'c-table--highlight': highlight,
        'c-table--layout-fixed': layoutFixed,
        'c-table--narrow': narrow,
        'c-table--no-hover': noHover,
        'c-table--responsive': responsive,
        'c-table--sticky-column': stickyColumn,
        'c-table--sticky-head': stickyHead,
        'c-table--wide': wide,
        'js-table-responsive': responsive,
        'has-sticky-head': hasStickyHead,
        'has-sticky-column': hasStickyColumn,
    }, className).trim();
    return (React.createElement("div", { className: classNameList, ref: tableWrapperRef, style: stickyColumn || stickyHead ? { position: 'relative' } : undefined },
        React.createElement(TableContext.Provider, { value: { tableHeader, responsive } },
            React.createElement("table", { role: "table", "data-testid": testId, ...otherProps }, children)),
        stickyColumn && React.createElement("div", { ref: stickColumnRef, style: trackingColumnStyle, "aria-hidden": true }),
        stickyHead && React.createElement("div", { ref: stickRowRef, style: trackingRowStyle, "aria-hidden": true })));
};
Table.Caption = Caption;
Table.Thead = Thead;
Table.Tbody = Tbody;
Table.Tfoot = Tfoot;
Table.Tr = Tr;
Table.Th = Th;
Table.Td = Td;
/**
 * Table Caption component.
 * @constructor
 */
function Caption({ children }) {
    return React.createElement("caption", null, children);
}
/**
 * Table Head component.
 * @constructor
 */
function Thead({ children, testId }) {
    return (React.createElement(TheadOrTbodyContext.Provider, { value: { type: 'head' } },
        React.createElement("thead", { role: "rowgroup", "data-testid": testId }, children)));
}
/**
 * Table Body component.
 * @constructor
 */
function Tbody({ children, testId }) {
    return (React.createElement(TheadOrTbodyContext.Provider, { value: { type: 'body' } },
        React.createElement("tbody", { role: "rowgroup", "data-testid": testId }, children)));
}
/**
 * Table Footer component.
 * @constructor
 */
function Tfoot({ children, testId }) {
    return (React.createElement("tfoot", { role: "rowgroup", "data-testid": testId }, children));
}
/**
 * Table Row component.
 * @constructor
 */
function Tr({ children, className, disabled, selected, status, ...otherProps }) {
    const tableContext = useContext(TableContext);
    const headOrBodyContext = useContext(TheadOrTbodyContext);
    const classNameList = classNames({
        // Convenience props.
        'is-disabled': disabled,
        'is-selected': selected,
        [`is-${status}`]: status,
    }, className).trim();
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    React.createElement("tr", { className: classNameList, role: "row", "aria-disabled": disabled ?? null, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: disabled ? '' : null, ...otherProps }, headOrBodyContext?.type === 'body' && tableContext?.responsive
        ? React.Children.map(children, (child, index) => {
            /**
             * Skip {@link Table.Th} and assume it is the first child.
             */
            if (index === 0) {
                return child;
            }
            return React.cloneElement(child, {
                'data-columnheader': tableContext?.tableHeader?.[index - 1],
            });
        })
        : children));
}
/**
 * Table Header component.
 * @constructor
 */
function Th({ children, className, disabled, status, ...otherProps }) {
    const headOrBodyContext = useContext(TheadOrTbodyContext);
    const classNameList = classNames({
        // Convenience props.
        'is-disabled': disabled,
        [`is-${status}`]: status,
    }, className).trim();
    let scopeVal;
    let roleVal;
    if (headOrBodyContext?.type === 'head') {
        scopeVal = children ? 'col' : undefined;
        roleVal = 'columnheader';
    }
    else if (headOrBodyContext?.type === 'body') {
        scopeVal = children ? 'row' : undefined;
        roleVal = 'rowheader';
    }
    return (React.createElement("th", { className: classNameList, scope: scopeVal, role: roleVal, "aria-disabled": disabled, ...otherProps }, children));
}
/**
 * Table Data Cell component.
 * @constructor
 */
function Td({ children, className, disabled, status, ...otherProps }) {
    const classNameList = classNames('c-table__cell', {
        // Convenience props.
        'is-disabled': disabled,
        [`is-${status}`]: status,
    }, className).trim();
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
    React.createElement("td", { className: classNameList, role: "cell", inert: disabled ? '' : null, ...otherProps }, children));
}
