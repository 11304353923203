import ContainerQuery from '@vwfs-bronson/bronson-default/src/js/module/container-query';
/**
 * Iterates over a collection of {ResizeObserverEntry}.
 * 1. Check whether the resized target has size data
 *    assigned to it {@see ContainerQuery.hasSizeData} via CSS custom Properties.
 * 2. Assign the respective data-* attributes to the target to act as CSS style hooks.
 * @param {ResizeObserverEntry[]} entries - A collection of observed resizes.
 */
export const apply = (entries) => entries
    .filter(({ target }) => ContainerQuery.hasSizeData(target))
    .forEach((entry) => ContainerQuery.setBreakpoints(entry));
export default ContainerQuery;
