import React from 'react';
import classNames from 'classnames';
/**
 * Bronson ButtonContainer object (component).
 * @constructor
 */
export function ButtonContainer({ children, className, testId, center, left, right, stretch, nav, vertical, noWrap, wrapReverse, reverse, leftSmall, centerSmall, rightSmall, verticalSmall, reverseSmall, ...otherProps }) {
    const divClassNameList = classNames({
        'o-button-container ': true,
        // main class modifier convenience prop extension
        'o-button-container--center ': center,
        'o-button-container--left ': left,
        'o-button-container--right ': right,
        'o-button-container--nav ': nav,
        'o-button-container--stretch ': stretch,
        'o-button-container--vertical ': vertical,
        'o-button-container--nowrap ': noWrap,
        'o-button-container--wrap-reverse ': wrapReverse,
        'o-button-container--reverse ': reverse,
        /**
         * `o-button-container--center-wrap` was replaced in Bronson.
         * @see https://jira.tools.platform.vwfs.io/browse/BRON-5562
         */
        'o-button-container--left@s ': leftSmall,
        'o-button-container--center@s ': centerSmall,
        'o-button-container--right@s ': rightSmall,
        'o-button-container--vertical@s ': verticalSmall,
        'o-button-container--reverse@s ': reverseSmall, // Convenience prop
    }, className).trim();
    function renderChildren() {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }
            const buttonClassName = `o-button-container__button ${child.props.className ? child.props.className : ''}`;
            return React.cloneElement(child, { className: buttonClassName });
        });
    }
    // generated main result
    return (React.createElement("div", { ...otherProps, "data-testid": testId, className: divClassNameList }, renderChildren()));
}
