import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/*
 * Bronson FormHeading component.
 */
export function FormHeading({ children, className, description, icon, testId, ...otherProps }) {
    const legendClassNameList = classNames('c-form-heading', className).trim();
    const iClassNameList = classNames('c-form-heading__icon').trim();
    function renderIfIcon() {
        if (icon) {
            return React.createElement(Icon, { className: iClassNameList, name: icon });
        }
        return null;
    }
    function renderIfText() {
        if (description) {
            return React.createElement("small", { className: "c-form-heading__text" }, description);
        }
        return null;
    }
    return (React.createElement("legend", { ...otherProps, "data-testid": testId, className: legendClassNameList },
        React.createElement("strong", { className: "c-form-heading__title" },
            renderIfIcon(),
            React.createElement("span", { className: "c-form-heading__title-text" }, children)),
        renderIfText()));
}
