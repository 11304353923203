import React from 'react';
import classNames from 'classnames';
/**
 * The InlineGroup arranges items with defined horizontal spacing in a row.
 * If the items do not fit into one row, they will wrap to the next row with defined vertical spacing.
 * @see https://bronson.vwfs.tools/default/docs/objects/inline-group.html
 * @constructor
 */
export function InlineGroup({ align = 'top', children, className, small, stretch, testId, ...otherProps }) {
    const classNameList = classNames('o-inline-group', className, {
        'o-inline-group--small': small,
        'o-inline-group--stretch': stretch,
        /**
         * Since the default for {@link align} is `top` no modifier class is needed.
         * Only applicable when {@link align} is set to either `bottom` or `center`.
         */
        [`o-inline-group--${align} `]: align === 'bottom' || align === 'center' ? align : null, // Convenience prop
    }).trim();
    return (React.createElement("div", { className: classNameList, ...otherProps, "data-testid": testId }, children));
}
/**
 * Internal item for the {@link InlineGroup} component.
 * @internal
 * @constructor
 */
export function InlineGroupItem({ children }) {
    return React.createElement("div", { className: "o-inline-group__item" }, children);
}
InlineGroupItem.displayName = 'InlineGroupItem.Item';
InlineGroupItem.Item = InlineGroupItem;
