import React, { useContext } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
const ButtonGroupContext = React.createContext({});
/**
 * Bronson ButtonGroup component.
 * @constructor
 */
export function ButtonGroup({ auto, buttonGroupName, buttonGroupType, children, className, componentDescription, errorMessage, }) {
    const contextValue = {
        buttonGroupType,
        buttonGroupName,
    };
    function renderIfComponentDescription() {
        if (componentDescription) {
            return React.createElement("p", null, componentDescription);
        }
        return null;
    }
    const divClassNameList = classNames('c-button-group', {
        // main class modifier convenience prop extension
        'c-button-group--auto': auto, // Convenience prop from Bronson variants.
    }, className).trim();
    function renderIfButtonGroupErrorMessage() {
        if (errorMessage) {
            return React.createElement("p", { className: "c-error-message" }, errorMessage);
        }
        return null;
    }
    return (React.createElement(React.Fragment, null,
        renderIfComponentDescription(),
        React.createElement("div", { className: divClassNameList },
            React.createElement(ButtonGroupContext.Provider, { value: contextValue },
                children,
                " ")),
        renderIfButtonGroupErrorMessage()));
}
/**
 * Bronson ButtonGroupItem component (nested).
 * @constructor
 */
function ButtonGroupItem({ handle, index, buttonGroupItemIcon, children, testId, ...otherProps }) {
    const context = useContext(ButtonGroupContext);
    // eslint-disable-next-line no-param-reassign
    handle = handle || context.buttonGroupName;
    const iClassNameList = classNames({
        'c-button-group__icon ': true,
    }).trim();
    function renderIfButtonGroupItemIcon() {
        if (buttonGroupItemIcon) {
            return React.createElement(Icon, { className: iClassNameList, name: buttonGroupItemIcon });
        }
        return null;
    }
    return (React.createElement("label", { "data-testid": testId, className: "c-button-group__item", htmlFor: `button-group-item-${handle}-${index}` },
        React.createElement("input", { className: "c-button-group__input", type: context.buttonGroupType, name: `button-group-item-${handle}`, id: `button-group-item-${handle}-${index}`, ...otherProps }),
        React.createElement("span", { className: "c-button-group__label" },
            renderIfButtonGroupItemIcon(),
            children)));
}
ButtonGroupItem.displayName = 'ButtonGroup.Item';
ButtonGroup.Item = ButtonGroupItem;
