import React from 'react';
import classNames from 'classnames';
/*
 * Bronson Progress component.
 */
export function Progress({ className, error, success, value, testId, ...otherProps /* in <progress> tag */ }) {
    const progressClassNameList = classNames('c-progress', {
        // main class modifier convenience prop extension
        'is-success ': success,
        'is-error ': error, // Convenience prop from Bronson variants.
    }, className).trim();
    return React.createElement("progress", { ...otherProps, "data-testid": testId, className: progressClassNameList, max: "100", value: value });
}
